.panel_flashy {
    color:rgb(121, 154, 177);
    /* background-color:#FF934F;
    border-color:#ff934f; */
}

.calculatorConatinerInfo {
    border-style: double;
    border-width: 1px;
  
   width: 1000px;
   border: 3px solid black;
   padding: 10px;
  }