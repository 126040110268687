.mainLengthMapCart{

    background-color: bisque;
    border: 2px solid black;
    height: 903px;
    width: 1000px;
}

.leftContainer{
    width: 940px;
    height:703px;

    border: 2px solid red;
    margin-left: 10px;

    float: left;
    display: block;
    position: relative;
}
.mapContainer{
    width: 400px;
    height: 500px;

    border: 2px solid black;
    margin-left: 10px;
    float: right;

}

.rightContainer{
    margin-left: 10px;
    width: 250px;
    margin-right: 10px;
    height: 100%;

    border: 2px solid black;
    float: right;
}



#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 500px;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}