.buyer-details {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space elements evenly */
  }
  /* Styles for the buyer details labels */
  .buyer-details label {
    font-weight: bold;
  }
  /* Styles for left and right divs */
  .left-div, .right-div {
    flex: 1; /* Make both divs grow equally */
  }
  /* Styles to align text to the right */
  .right-div {
    text-align: right;
  }

  .hr50 {
    width: 20%; /* Set width to 50% */
    margin: 0 ; /* Center horizontally */
  }

  .right-tools-div {
    display: flex;
    justify-content: space-between; 
    padding: 5px;
  }

  .left-tools-div {
    text-align: right;
    padding: 5px;
  }

  .float-left {
    float: left;
}

.float-right {
    float: right;
}