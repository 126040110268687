.superMainPC {
  border: 1px solid black;
  width: 1000px;
  background-color: azure;
  height: 1390px;

}


.plantInner{
        height: 220px;
        /* background-image: radial-gradient(circle at 2px 2px, black 2px, transparent 0);
        background-size: 16px 16px; */
        width: 198px;
  
        zoom: 2.5;
        /* border: 1px solid red; */

      
}

.innerPlotMain {
 height: 200px;
 width: 178px;

 /* background-size: 20px 10px;
 background-image: radial-gradient(circle at 2px 2px, red 2px, transparent 0); */
 margin-left: 10px;
 margin-top: 10px;
 background-color: blanchedalmond;
 position: absolute;
 font-size: 2px;
 font-weight: bold;
 
    display: flex;
    justify-content: center;
    align-items: center;
}


.divMain{
    float: left;
    margin-left: 5px;
    width: 500px;
    /* border: solid black 1px ; */
    counter-reset: headingPlant  headingPlant_ColumnBoundary headingPlant_RowBoundary ;
  
}
.plant_ColumnBoundary1::before {
    content: counter(headingPlant_ColumnBoundary);
    counter-increment: headingPlant_ColumnBoundary;
    font-size: 2px;
    text-align: center;
   
    display: flex;
    justify-content: center;
    align-items: center;
}
.plant_RowBoundary1::before {
    content: counter(headingPlant_RowBoundary);
    counter-increment: headingPlant_RowBoundary;
    font-size: 2px;
    text-align: center;
   
    display: flex;
    justify-content: center;
    align-items: center;
}

.divMainResult{
  border: 1px solid black;
  width: 440px;
  margin-left: 10px;

  margin-bottom: 20px;
  margin-top: -90px;
}


.plant::before {
    content: counter(headingPlant);
    counter-increment: headingPlant;
    font-size: 2px;
    text-align: center;
   
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    /* repeat(4, [] 5px [col-end]) */
  }

.br1{
    height: 10px;
    width: 178px;
    font-size: 2px;
    text-align: center;
    margin-left: 10px;
    vertical-align: middle;
    position: absolute;
    float: left;
    background-color: gold;
   font-weight: bold;
 
   
  display: flex;
  justify-content: center;
  align-items: center;
}

.br2{
    height: 10px;
    width: 178px;
    font-size: 2px;
    text-align: center;
    margin-top: 210px;
    float: left;
    position: absolute;
    background-color: gold;
    margin-left: 10px;
    font-size: 5px;
    font-weight: bold;

     
    display: flex;
    justify-content: center;
    align-items: center;
}
.bc1{
    /* width: 10px; */
    width:  var(--widthBC1); 
    height: 220px;
    background-color: sandybrown;
    writing-mode: vertical-rl; 
    float: left;
    position: absolute;
    font-size: 2px;
    text-align: center;
    font-weight: bold;
     
    display: flex;
    justify-content: center;
    align-items: center;
}
.bc2{
    writing-mode: vertical-lr;
    width: 10px;
    height: 220px;
    background-color: sandybrown;
    text-align: center;
    position: absolute;
     margin-left: 188px ;
   font-size: 2px;
   font-weight: bold;
    
   display: flex;
   justify-content: center;
   align-items: center;
   
}



  .fontInfo{
      font-size: 6px;
      
  }

  .tableInner
{
    table-layout:fixed;
    width:100%;
    height: 100%;
    background-color: blanchedalmond;
}

.td_Pc, .th_pc {
    /* border: 1px solid #dddddd; */
 
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 0%;
    border: 0.1px solid black;

  }
  
  .tr_pc:nth-child(even) {
    background-color: #dddddd;
  }

  .divHeadingContainer {
    padding: 10px;
  }

  .td_BC{
    
    background-color: sandybrown;
  }
  .td_RC{
    background-color: gold;
  }


  .contaninerInputPlantsDimensions {
      height: 376px;
   
      border: black 1px solid;
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      width: 440px;
      margin-bottom: 20px;
  }

  .aligmentText{
      text-align: center;

  }
  .divBoundaryRow{
    background-color: gold;
  }

  .divBoundarycolumn{
    background-color: sandybrown;
  }

  .divRow_Row{
    background-color: blanchedalmond;
  }

  .div_Column_Column{
    background-color: blanchedalmond;
  }
  .graph{
      display: inline-block;
      position: relative;

  }



.tdIputMain{
    width: 70%;
    
}

  .tdIput{
      width: 50%;
      
  }


.td_Result_br {
  background-color: gold;
  width: 33%;
}

.td_Result_bc {
  background-color: sandybrown;
  width: 33%;
}

.td_Result_InnerPlot {
  background-color: blanchedalmond;
  width: 33%;
}
