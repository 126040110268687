.FerNutCalculator{

    background-color: white;
    height:2700px ;
    width: 1000px; 
     border: 1px solid black;
     margin-left : 10px;
     padding: 10px;
}
.div_SuperF_N{
    width: 99%;
    background-color: azure;
    border: 1px solid #000000;
    padding: 5px;
    height: 750px;

}
.fertiliserRequiredContainer {

     padding: 10px;
     background-color: antiquewhite;
     border: 1px solid black;
     width: 48%;
     float: left;
     margin-right: auto;
     height:690px ;
     margin-left: 10px;
     margin-top: 20px;
}
.nutrientRequiredContainer {

    padding: 10px;
    background-color: antiquewhite;
    border: 1px solid black;
    width: 48%;
    float: left;
    margin-left: 20px;
    margin-right: auto;
    height:690px ;
    margin-top: 20px;
}

.fertilizerUnit {
    background-color: cadetblue;
    padding: 5px;
    height: 20px;
    color: white;
    
}
.fertilizerUnitInput {
    background-color: cadetblue;
    padding: 3px;
    height: 30px;
    color: white;
  font-size: 16px;
  width: 150px;
  border: 3px solid black;
}
.nutrientUnitInput {
    background-color: red;
    padding: 3px;
    height: 35px;
    color: white;
  font-size: 16px;
  width: 150px;
  border: 3px solid black;
}

.nutrientUnit {
    background-color: red;
    padding: 5px;
    height: 20px;
    color: white;
}

.nutrientPercentageUnit {
    background-color: orange;
    padding: 4px;
    height: 20px;
    color: black;
}

.nutrientPercentageUnitInput {
    background-color: orange;
    padding: 5px;
    height: 30px;
    color: white;
  font-size: 16px;
  width: 150px;
  border: 3px solid black;
}



.operatorFN{
    size: 20px;
    height: 25px;
    font-weight: bolder;
    padding: 2px;
}

.nestedFN{
    line-height: 1.9;
}