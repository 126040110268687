.mianNitrogenContainter{
    height: 1560px;
    border: 2px black solid;
    text-align:  center;
    margin-top: 20px;

    background-color: bisque;
}

.subContainerTable{

    height: 970px;
    /* border: 2px black solid ; */
    padding: 10px ;
    margin: 5px;
}
.trHeading {
    font-size: 25px;
    font-weight: bolder;
    text-align:  center;
   
}

.h1Headng {
    font-size: 31px;
    font-weight: bolder;
}
.nestedTableN{

    border: black solid 1px;
}
.trNormal{

    font-size: 28px;
}
.trN {

    text-align: right;
    background-color: blanchedalmond;
}
.trN:nth-child(even) {
    background-color: floralwhite;
  }

  .buttonStyleN{
    margin: auto;
    margin-left: 15px;
    height: 50px;
    width: auto;

    color:   white;
    background-color : red;

   
  }
  .containerbuttonN {
    text-align: center;
  }
  .selectedN{
    background-color: green;
    color: white;
  }