

.parent-container-tools {
    padding: 5px;
    text-align: right; /* Aligns all text and inline elements to the right */
    /* background-color: aqua; */
    height: 45px;
  }
  
  .child-element-tools {
    float: right; /* Floats the element to the right */
  }

  