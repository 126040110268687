
.superMain1{
    text-align: center;
  height: 460px;
  padding: 10px;
  background-color:  lemonchiffon;
  border: 2px solid black;
}
.subMain{
    margin: auto;
    width: 100%;
    height: 350px;
    border: 2px solid black;
    position: relative;
    padding: 4px;
}
.mainContinerPau1{
    height: 360px;
    

    position: absolute;
    width: 100%;
   
 
    z-index: 1;
}



.bg-imagee {
    /* The image used */
    background-image: url('../Assests/ONLLOGO192.png'),url('../Assests/ONLLOGO192.png');
   
    /* Add the blur effect */
  
    filter: blur(4px);
    -webkit-filter: blur(4px);
    object-fit: contain;
    /* Full height */
   
    
    height: 100%;

    /* Center and scale the image nicely */
   
    background-repeat: round;
    z-index: -1;
 
  }
  .imageCustomThigs1{
    height: 340px;
    object-fit: contain;
    
}