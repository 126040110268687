.mainContinerPau{
    height: 560px;
    padding: 1px;
    border: 2px solid black;
}

.superMain{
  text-align: center;
    height: 760px;
    padding: 10px;
    background-color: bisque;
    border: 2px solid black;
}
.imageCustomThigs{
    height: 550px;
    object-fit: contain;
}
