.WheatRiceCalculator{
    height: 1570px;
    border: 1px solid black;
    width: 1000px;

    padding: 10px;
}


.canvaseAttibite {
    height: 100px;
     width: 300px;
     border: 1px solid red ;
}

.div_SubContainer_Rice{
    height: 1490px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: bisque;
    border: 1px solid black;
    padding: 10px;
    margin-top: 20px;
}

.td_riceGovt{
   background-color: antiquewhite;
   

}
.td_riceGovt {
    background-color: azure;
}

.td_riceGovt:nth-child(even) {
    background-color: azure;
  }


.td_riceFarmer {
    background-color: floralwhite;
}

.td_riceFarmer:nth-child(even) {
    background-color: floralwhite;
  }

.td_riceLabour {
    background-color: lavender;
}

.td_riceLabour :nth-child(even) {
    background-color: lavender;
  }


  .boldSize{
    font-size: 30px;
    font-style: normal;
  }

  .tdAvg{
    background-color: khaki;
  }