.mapCelMain{
    height: 40px ;
    width: 950px;
  
    font-size: small;
    border: 1px solid black;
}
.trCity{
    border: 1px solid black;
}

.mapCellBackGroundFeatures{
    background-color: aqua;
}
.mapCellBackGroundAmritsar{
    background-color: aqua;
}

.mapCellBackGroundBathinda{
    background-color: goldenrod;
}

.mapCellBackGroundBarnala{
    background-color: #e16565;
}
.mapCellBackGroundFaridkot{
    background-color: black;
    color: aliceblue;
}
.mapCellBackGroundFatehgarh{
    background-color: #727289;
}
.mapCellBackGroundfazilka{
    background-color: chocolate;
}
.mapCellBackGroundFerozepur{
    background-color: royalblue;
}
.mapCellBackGroundGurdaspur {
    background-color: rgb(144, 200, 89);
}
.mapCellBackGroundHoshiarpur{
    background-color: crimson;
}
.mapCellBackGroundJalandhar{
    background-color: darkkhaki;
}
.mapCellBackGroundKapurthala{
    background-color: darkorange;
}
.mapCellBackGroundLudhiana{
    background-color: fuchsia;
}
.mapCellBackGroundMansa{
    background-color: blueviolet;
}
.mapCellBackGroundMoga{
    background-color: aquamarine;
}
.mapCellBackGroundSahibzadaAjitSinghNagar{
    background-color: bisque;
}
.mapCellBackGroundMuktsar{
    background-color: lightcoral;
}
.mapCellBackGroundPathankot{
    background-color: lightgreen;
}
.mapCellBackGroundPatiala{
    background-color: mediumorchid;
}
.mapCellBackGroundRupnagar{
    background-color: #9a9ae1;
}
.mapCellBackGroundSangrur{
    background-color: olivedrab;
}
.mapCellBackGroundShahidBhagatSinghNagar{
    background-color: grey;
}
.mapCellBackGroundTarnTaran{
    background-color: orchid;
}