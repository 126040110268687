

.FardBackgrondPlus{

    background-color: rgb(254, 254, 254);
    height:3000px ;
    width: 1000px; 
     border: 1px solid black;
     margin-left : 10px;
     padding: 10px;
}

.spanHeading{
    color: Red;
    font-size: large;
    font-weight: bold;
} 
.calculatorFard{
    background-color: azure;
    border: 1px solid #000000;
    padding: 5px;
}


