.p_imageContainer_right {
    height: 200px;
    width: 200px;
    float: right;
    clear: both;
    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 25px;
}

.p_imageContainer_left {
    height: 200px;
    width: 200px;
    float: left;
    margin: 5px;
    padding: 10px;

    border: 1px solid black;
    border-radius: 25px;
    
}
