.btnRF-add {
    background-color: #28a745; /* Green color for success */
  }
  
  .btnRF-edit {
    background-color: #F9E795; /* Blue color for primary */
    color: black;
    
  }
  
  .btnRF-delete {
    background-color: #dc3545; /* Red color for danger */
  }
  
  .btnRF-cross {
    background-color: #adb5bd; /* Gray color for secondary */
  }
  
  .btnRF-save {
    background-color: #17a2b8; /* Light blue color for info */
  }

  .btnRF-Refress {
    background-color: #adb5bd; /* Light blue color for info */
  }

  .btnRF-back {
    background-color: dodgerblue; /* Green color for success */
  }
  


  .standardPading{
    padding: 5px;
  }
  .standardMargin{
    margin: 5px;
  }