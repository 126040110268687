.SuperFCContainer {
    margin-top: 20px;
    margin-left:20px;
    margin-right: 20px;
}



.commonFC2_Input {
    width: 95%;
    height: 30px;
    text-align: center;
}

.commonFC2_tr {
   
    text-align: center;
}
.commonResultSpan{
    font-weight: bolder;
    background-color: orange;
    border: 1px black solid;
    padding: 8px;
    opacity: 50%;

}

.commonResultSpanPure{
    font-weight: bolder;
    background-color: cadetblue;
    padding: 8px;
    border: 1px black solid;
    opacity: 50%;

}



.tableContainerFM2_Nitrogen{

    margin-left: 10px;
    margin-right: 10px;
    background-color: gr;
    margin-top: 20px;
    border: 2px solid black;
    background-color: darkgray;
}

.tr_N{
    background-color: darkgray;
}
.tr_N:nth-child(even) {
    background-color: #dddddd;
  }




.tableContainerFM2_P{

    margin-left: 10px;
    margin-right: 10px;
    background-color: gr;
    margin-top: 20px;
    border: 2px solid black;
    background-color: lightpink;
}

.tr_P{
    background-color: lightpink;
}
.tr_P:nth-child(even) {
    background-color: lightpink;
  }
