.FooterContainerMain {
    height: 450px ;
    width: 1000px;
    padding: 1%;

    border: 3px gray solid ;

     padding: 10px;
     margin-left: auto;
     margin-right: auto;
}

.mapDivFooter{
    margin-top: 20px;
    height: 180px;
    float:  right;
     width: 180px;
    border: 1px black solid;

}

.imageAttibuteFooter{
    float: left;
    height: 100px;
    width: 100px;
}
.imageAttibuteFooterSocialSite{
    float: left;
    height: 50px;
    width: 50px;
}
.ContactDiv{
    float: left;
}