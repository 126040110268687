.TextBannerAttributePotato {
    color: blue;
    background-color: lightgreen;
    font-size: 20px;
    /* <marquee direction = "right">This text will scroll from left to right</marquee> */
}

.clonesPotato {
    color: red;

}