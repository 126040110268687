.dashboard {
    display: flex;
   
  }
  
  .sidebar {
    width: 20%;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  }