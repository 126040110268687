.rajiFarmTile1_Span {

    color: forestgreen;

    font-weight:bold ; 

    font-size: 40px;

}
.rajiFarmTile2_Span {

    font-weight:bold ; 
    color: forestgreen;

}

.lifeMember_Span {

    font-weight:bold ; 

    color: red;
}

.rajiFarm_poplarTile2_Span {

    color: forestgreen;
    font-weight:bold ; 

}