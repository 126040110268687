

.DaysCalculatorBackgrondPlus{

    background-color: rgb(254, 254, 254);
    height:1000px ;
    width: 1000px; 
     border: 1px solid black;
     margin-left : 10px;
     padding: 10px;
}

.spanHeading{
    color: Red;
    font-size: large;
    font-weight: bold;
} 
.calculatorFard{
    background-color: azure;
    border: 1px solid #000000;
    padding: 5px;
}



h1 {
    color: #333;
}

#counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

#counter {
    font-size: 6em;
    margin-bottom: 20px;
    color: #009688;
    font-family: 'Arial', sans-serif;
    text-align: center;
    margin: 50px;
}

button {
    font-size: 1.5em;
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #009688;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
   
}

button:hover {
    background-color: #00796b;
}
.buttonDelete {
    font-size: 1.5em;
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color:  #ec7979;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.buttonDelete:hover {
    background-color: #e01111;
}
.inputfieldValue{
    width: 90%;
}

#entries-table {
    margin-top: 20px;

    border-collapse: collapse;
}

#entries-table th, #entries-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

#entries-table th {
    background-color: #009688;
    color: #fff;
}

#entries-table tr:nth-child(even) {
    background-color: #f2f2f2;
}