.mainContainerWorkShop{
    width: 97%;
    height: 5890px;
    background-color: bisque;
    padding: 10px;
    margin: 10px;
}


ul.b {
    list-style-type: square;
    color: black;

    
  }

  .a {
    overflow:auto;
    list-style-type: disc;
    font-size: large;
    color: red;

  }
  
  .subMainHeading{
    color: darkblue;
    
  }
  .p_DivContauner {
    margin: 5px;
    display: inline;
  }


  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: lavender;
  }


  .imageContainer_Table_insect {
    height: 200px;
    width: 200px;
    float: left;
    margin: 5px;
    padding: 10px;

    border: 1px solid black;
    border-radius: 25px;
}