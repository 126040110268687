.mainContainerHome {
    border: 1px  black solid; 
    height: 11900px ;
    width: 1000px;
 
    padding: 10px;
    content: ce;
   
}


.aa {
    overflow:auto;
    list-style-type: disc;
    font-size: large;
  }