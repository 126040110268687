.div_SubContainer{
    height: 790px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: bisque;
    border: 1px solid black;
    padding: 10px;
    margin-top: 20px;
}

.volume_HeadingCenter{
    text-align: center;
}

.inputVoulme{
    width: 72%;

}

.buttonPumpsCapacity{
 width: 30%;

 height: 40px;
 background-color: red;
color: white;
font-size: larger;
 margin: 10px;
}

.buttonPumpsCapacityOn{
    color: white;
    background-color: green;
  
   }

.volume_Pump{
    color: green;
    font-size: xx-large;
}


.volume_Liquid{
    color: magenta;

}
.volume_Liquid_result{
    color: magenta;
    font-size: xx-large;
    
}
.volume_LiquidInput{
    border: 2px solid magenta;
    color: magenta;
    text-align: right;
}

.tr_LiquidP{
    background-color: lavender;
}

.tr_otherPumps{
    background-color: azure;
}


.volume_waterInput {
    color: blue;
    border: 3px solid blue;
    text-align: right;
}

.volume_waterBasic {
    color: blue;
   
}

.volumeWidth {
    width: 50%;
}