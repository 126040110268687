.mainView{
    height: 5320px;
   
    border: 1px solid black;
    width: 1000px;
    margin-top: 10px;
    padding: 10px;
}

.divTable_Fer{
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    zoom: 0.7;
    margin-bottom: 20px;
}
.divImageElement {
    margin-top: 20px;
}
.td_color_Non_EM {
    background-color: skyblue;
    font-size: 17px;

}

.td_color_EM {
    background-color: rgb(157, 203, 88);
    font-size: 17px;

}

.td_color_BME{
    background-color: yellow;
    font-size: 17px;
   
}
ol.fertC {list-style-type: decimal;}

.primaryFer{
    background-color: bisque;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.secondaryFer{
    background-color: cornsilk;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.micronutrients_div{
    background-color: honeydew;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}