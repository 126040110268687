.main_Role_CommonComplexFContanier{

    width: 100%;
    height: 600px;
    background-color: beige;
    border: 1px  solid black;

}
.h3_role {
    text-align: center;
}
.tableContiner_role {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}
.ccf_Nitrogen{

    background-color: cadetblue;
    padding: 2px;
}


.ccf_Phosphorus{
   background-color: lightpink;
   padding: 2px;
}

.ccf_potash{
    background-color: lightcoral;
    padding: 2px;
   
 }