.p_div_SubContainer{
    height: 650px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: lightyellow;
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
}

.p_volume_HeadingCenter{
    text-align: center;
}

.p_inputVoulme{
    width: 72%;

}

.p_buttonPumpsCapacity{
 width: 30%;

 height: 40px;
 background-color: red;
color: white;
font-size: larger;
 margin: 10px;
}

.p_buttonPumpsCapacityOn{
    color: white;
    background-color: green;
  
   }

.p_volume_Pump{
    color: green;
    font-size: xx-large;
}


.p_volume_Liquid{
    color: magenta;

}
.p_volume_Liquid_result{
    color: magenta;
    font-size: xx-large;
    
}
.p_volume_LiquidInput{
    border: 2px solid magenta;
    color: magenta;
    text-align: right;
}

.p_tr_LiquidP{
    background-color: lavender;
}

.p_tr_otherPumps{
    background-color: azure;
}


.p_volume_waterInput {
    color: blue;
    border: 3px solid blue;
    text-align: right;
}
.p_volume_pumpInput {
    color: green;
    border: 3px solid green;
    text-align: right;
}

.p_volume_waterBasic {
    color: blue;
    font-size: x-large;
   
}

.p_volumeWidth {
    width: 50%;
}