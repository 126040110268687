
.RoughCalculateBackgrondPlus{

    background-color: rgb(199, 244, 233);
    height:500px ;
    width: 1000px; 
     border: 1px solid black;
     margin-left : 10px;
     padding: 10px;
}
