.TextBannerAttribute {
    color: blue;
    background-color: lightpink;
    font-size: 20px;
    /* <marquee direction = "right">This text will scroll from left to right</marquee> */
}

.clones {
    color: red;

}

.Address-banner-Attribute {
    color: blue;
    background-color: lightsteelblue;
    font-size: 20px;
    /* <marquee direction = "right">This text will scroll from left to right</marquee> */
}

.class-Note{
    color: red;
    display: inline-block;
    font-weight: bold;
    font-size: 24px; /* Adjust the font size as needed */
    animation: blink .5s step-start infinite, scale 1.5s ease-in-out infinite;
}

  
  /* Blinking effect */
  @keyframes blink {
    50% {
      opacity: 0; /* This makes the text invisible */
    }
  }
  
  /* Scaling effect */
  @keyframes scale {
    0%, 100% {
      transform: scale(1); /* Normal size */
    }
    50% {
      transform: scale(1.2); /* Slightly larger */
    }
  }


  .class-Clone{
    color: red;
    display: inline-block;
    font-weight: bold;
    font-size: 24px; /* Adjust the font size as needed */
    animation:  scale-Clone 1.5s ease-in-out infinite;
}


  /* Scaling effect */
  @keyframes scale-Clone {
    0%, 100% {
      transform: scale(1); /* Normal size */
    }
    50% {
      transform: scale(.9); /* Slightly larger */
    }
  }

  .class_vision{
    color: green;
  }