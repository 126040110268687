.mainLengthCalContainer{

    background-color: bisque;
    border: 2px solid black;
    height: 500px;
    width: 1000px;

   
}

 .divUnit{

    font-weight: bolder;
 }

.mainSubHeading{
    text-align: center;
    margin-top: 20px;
}
.widthExtra {
    width: 35%;
}
.labelme {
    margin-left: 10px;
    margin-right: 10px;
}
.inputExtra {
    text-align: right;
    margin-left: 10px;
    margin-right: 10px;
}
.alnright{
    text-align: right;
}
.tableExtra {
    margin: 10px;
    padding: 10px;
    width: 97%;
    margin-top: 30px;

}
.inlineExtra{

    float:  left;
}