.mainMoneyCalculator {
 
    border: 1px black solid; 
    height: 1050px ;
    width: 1000px; 
    margin-left: 10px ;
    padding: 10px;
    content: ce;

}

.heading{
    text-align: center;
    float : left ;
    
}
.headCleanbutton{
  
    float : right ;
    width: 150px;
    font-size: 40px;
    font-weight: bolder;
    
}
.headCleanbutton2{
  
    float : center ;
    width: 100px;
    font-size: 40px;
    font-weight: bolder;
    
}

.imageContainer_Table_Notes {
    height: 50px;
    width: 100px;
    float: left;
    margin: 5px;
    padding: 2px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;

}
.table1 {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .td1, .th1 {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .imagecolumnAttribute{
    width : 20%
  }
  .imageNoteAttribute{
    width : 20%; 
  
  }
  .imageNoteValueAttribute{
    font-size: 25px;
  }

  .tdRedult{
    font-size: 35px;
  }

  .imageNoteInputeAttribute{
    width: 45%;
  }



  .tr2 {
    background-color: lavender;
    font-size: larger;
    font-weight: bolder;
    font-size: 30px;
  }

  .tr1:nth-child(even) {
    background-color: #dddddd;
  }